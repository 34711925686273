import React from "react";

import {
    useLocation,
    Link
} from "react-router-dom";


import './index.css';

const Header = () => {

    const location = useLocation();
    const path_parts = location.pathname.substring(1).split("/");

    const portfolio_open = path_parts.length === 3 &&
			   path_parts[0] === 'portfolio' &&
			   path_parts[2] !== 'all';
    const header_parts = path_parts.filter(part => part !== 'all')
    
    if (portfolio_open)
	return <></>;
    
    return (
	<div className="main-header">
	    {header_parts.map((part,i) => <>
		<Link to={`/${header_parts.slice(0,i+1 ).join("/")}`} >
		    {part}
		</Link>
		{ i < path_parts.length - 2 && "/" }
	    </>)}
	</div>
    );  
};

export default Header;
