import React from "react";

import './index.css';

const About = () => {
    return (
        <div className="about">
	    <div className="photo">
		<div className="photo-holder">
		    <img src="/ernesto.jpg" alt="Ernesto Lozano" />
		</div>
	    </div>
	    <div className="about-content">		
		<div className="background-border-holder">
		    <div className="background-border"></div>
		</div>
		<div className="background"></div>
		<div className="the-content">
		    <div className="header">
			<h1>Ernesto Lozano Photography</h1>
		    </div>
		    <div className="content">
			<p>A while ago, I wrote an article about how awesome it is to run Linux. Since then, a few people have asked me for a quick guide on how to do it. I use the Arch Linux wiki myself, but I find it intimidating for newcomers, so I decided to write a simplified guide. You will need two flash drives, a computer capable of UEFI booting and secure boot disabled.</p>
			<p>Dual booting refers to the practice of having two or more operating systems living on your computer and choosing which one you’ll be working with for the current session.</p>
			<p>Dual booting refers to the practice of having two or more operating systems living on your computer and choosing which one you’ll be working with for the current session.</p>
			<p>--Ernesto</p>
		    </div>
		</div>
            </div>
        </div>
    );  
};

export default About;
