import React from "react";

import { NavLink, useLocation } from "react-router-dom";

import { IoLogoInstagram,
	 IoLogoFacebook,
	 IoIosImages } from "react-icons/io";

import { SiAboutDotMe } from "react-icons/si";
import { GiMountaintop } from "react-icons/gi";
import { GrContact } from "react-icons/gr";

import './index.css';

interface NavigationProps {
    className?:string;
};

const Navigation = ({ className } : NavigationProps) => {

    const location = useLocation();
    const path_parts = location.pathname.substring(1).split("/");

    const portfolio_open = path_parts.length === 3 &&
			   path_parts[0] === 'portfolio' &&
			   path_parts[2] !== 'all';
    
    return (
        <div className={`navigation ${className} ${portfolio_open?'collapsed':''}`}>
	    <div className="logo">
		<img src={`/${portfolio_open ? 'logo_icon.png' :'logo_black.png' }`} alt="Ernesto Lozano Fotography" />
	    </div>
	    <div className="links">
		<ul className="nav-list">
		    <li>
			<NavLink exact to="/about">
			    <SiAboutDotMe /><span>About</span>
			</NavLink>
		    </li>
		    <li>
			<NavLink exact to="/portfolio">
			    <IoIosImages /> <span>Portfolio</span>
			</NavLink>
		    </li>
		    <li>
			<NavLink exact to="/experiences">
			    <GiMountaintop /><span>Experiences</span>
			</NavLink>
		    </li>
		    <li>
			<NavLink exact to="/contact">
			    <GrContact /><span>Contact</span>
			</NavLink>
		    </li>
		</ul>
	    </div>
	    <div className="social">
		<ul className="social-list">
		    <li><a href="/instagram"><IoLogoInstagram /></a></li>
		    <li><a href="/facebook"><IoLogoFacebook /></a></li>
		</ul>
	    </div>
        </div>
    );  
};

export default Navigation;
