import React, { useState , useEffect} from "react";

import './index.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { Link,
	 RouteComponentProps,
	 useRouteMatch,
	 Switch,
	 useParams,
	 Route,
	 Redirect } from "react-router-dom";

import { IoMdArrowDropleftCircle, IoMdArrowDroprightCircle} from "react-icons/io";

import { GrClose } from "react-icons/gr";
import InnerImageZoom from 'react-inner-image-zoom';
import { motion } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";

type TParams =  { id: string };

type ImageData = {
    id:string,
    src:string,
    alt:string
};

type PortfolioEntrie  = {
    id:string,
    title:string,
    images:Array<ImageData>,
    size:number
};

type PortfolioEntriesType = Array<PortfolioEntrie>;

type PortfolioSlidesProps = {
    entry:PortfolioEntrie
};

const PortfolioSlides = ({entry}:PortfolioSlidesProps) => {    
    return (
	<div className="portfolio-single" >		    
	    {entry.images.map(image =>
		<Link key={image.id}
		      className="image"
		      to={`/portfolio/${entry.id}/${image.id}`}>
		    <img src={image.src}
			 alt={image.alt} />
		    <div className="info">
			<p>{image.alt}</p>
		    </div>
		</Link>)}
	</div>
    );
};

type PortfolioSlideProps = {
  entry:PortfolioEntrie  
};

const PortfolioSlide = ({entry}:PortfolioSlideProps) => {

    const { id } = useParams<TParams>();
    const current_index:number = entry.images.findIndex(image =>
	image.id === id);

    if (current_index === -1)
	return <div>Not found</div>;

    const current = entry.images[current_index];
    
    return (
	<div className="portfolio-slide" >
	    <div className="title">
		<Link to ={`/portfolio/${entry.id}/all`} >
		    <GrClose />
		</Link>
		<h2>{current.alt} </h2>
	    </div>
	    <div className="control">
		{current_index > 0 &&
		 <Link to={`/portfolio/${entry.id}/${entry.images[current_index-1].id}`}>
		     <IoMdArrowDropleftCircle />
		 </Link>}
	    </div>
	    <div className="image-holder">
		<InnerImageZoom
		    className="image-slide"
		    src={current.src}
		zoomScale={1}
				alt={current.alt} />
	    </div>
	    <div className="control">
		{current_index < entry.images.length - 1 &&
		 <Link to={`/portfolio/${entry.id}/${entry.images[current_index+1].id}`}>
		     <IoMdArrowDroprightCircle />
		 </Link>}
	    </div>

	</div>
    );
};

const PortfolioEntry  = ({match}  : RouteComponentProps<TParams>) => {
    
    let { path, url } = useRouteMatch();
    let id = match.params.id;
    const entry = portfolioEntriesData.find(entrie =>
	entrie.id === id );

    if ( entry === undefined )
	return <div>Nothing found</div>;

    return (
	<div className="portfolio">
	    <Switch>
		<Route exact path={path}>
		    <Redirect to={`${url}/all`} />
		</Route>
		<Route exact path={`${path}/all`} >
		    <PortfolioSlides
			entry={entry} />
		</Route>	
		<Route path={`${path}/:id`}>	    
		    <PortfolioSlide entry={entry} />
		</Route>
	    </Switch>
	</div>
    );  
};


const PortfolioEntries = () => {

    const images = portfolioEntriesData.map( entrie =>
	entrie.images.slice(0,3)).flat();
    const [ isLoading, setIsLoading ] = useState(true);    
    
    useEffect(() => {
	const cacheImages = async ( images : Array<ImageData> ) => {
	    const promises = await images
		.map( img => new Promise<void>((resolve, reject) => {
		    const image : HTMLImageElement = new Image();
		    image.src = img.src;
		    image.onload = (e) => resolve(); 
		    image.onerror = (e) => reject();

		}) );

	    try {
		await Promise.all(promises);
	    } catch (err) {
		console.log("Bithes");
		console.log(err);
	    }	    
	    setIsLoading(false);
	};
	
	cacheImages(images);
    },[images]);

    
    const variants = {
	hidden: { transform: 'scale(0.1)' },
	visible: { transform: 'scale(1)' },
    };
    
    const entries = portfolioEntriesData.map( entrie =>
	isLoading ?	
	<div className='loading-images' >
	    <ClipLoader size={300} />
	</div>
	:
	<motion.div
	    initial="hidden"
	    animate="visible"
	    variants={variants}>
	    <Link to={`/portfolio/${entrie.id}`}
		  className="entry"
		  key={`${entrie.id}`}>
		{ entrie.images.slice(0,3).map(image =>
		    <img src={image.src}
			 alt={image.alt} />
		  )}
		<div className="info">
		    <h2>{entrie.title}</h2>
		    <p>{entrie.size} photos</p>
		</div>
	    </Link>
	</motion.div>	 
    );
    return (
	<>
	    {entries}
	</>
    );
};

const portfolioEntriesData:PortfolioEntriesType = [
    {
	id:'trip-to-heaven',
	title:'trip to heaven',
	images:[
	    {
		id:'a-title-for-this',
		src:'/images/0001.jpg',
		alt:'A title for this'
	    },
	    {
		src:'/images/0002.jpg',
		id:'no-pues-si',
		alt:'No pues si'
	    },
	    {
		src:'/images/0003.jpg',
		id:'no-pues-si-jaja',
		alt:'No pues si haci que no'
	    }
	],
	size:12
    },
    {
	id:'trip-to-hell',
	title:'trip to hell',
	images:[
	    {
		src:'/images/0004.jpg',
		id:'a-si-que-no',
		alt:'A si que no'
	    },
	    {
		src:'/images/0005.jpg',
		alt:'Siete muertitos',
		id:'siete-muertitos'
	    },
	    {
		src:'/images/0006.jpg',
		alt:'Siete muertitos-lll',
		id:'siete-muertitos-lll'
	    }
	],
	size:26
    },
    {
	id:'trip-to-lassss',
	title:'trip to lasss',
	images:[
	    {
		src:'/images/0011.jpg',
		id:'a-si-que-no',
		alt:'A si que no'
	    },
	    {
		src:'/images/0008.jpg',
		alt:'Siete muertitos',
		id:'siete-muertitos'
	    },
	    {
		src:'/images/0009.jpg',
		alt:'Siete muertitos-lll',
		id:'siete-muertitos-lll'
	    }
	],
	size:26
    }
];


const Portfolio = () => {    

    let { path, url } = useRouteMatch();
    
    return (
	<div className="portfolio">
	    <Switch>
		<Route exact path={path}>
		    <Redirect to={`${url}/all`} />
		</Route>
		<Route exact path={`${path}/all`} >
		    <PortfolioEntries />
		</Route>	
		<Route path={`${path}/:id`}
		component={PortfolioEntry} />	    
	    </Switch>
	</div>
    );  
};

export default Portfolio;
