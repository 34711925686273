import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Navigation from '../Navigation';
import About from '../About';
import Experiences from '../Experiences';
import Portfolio from '../Portfolio';
import Header from '../Header';

import './index.css';

function App() {
    
    return (
        <Router>
	    <div className="site-content">          
		<Navigation className="nav" />
		<div className="main-content">
		    <Header />
		    <Switch>
			<Route exact path="/">
			    <Redirect to="/about" />
			</Route>
			<Route path="/about">
			    <About />
			</Route>
			<Route path="/experiences"
			component={Experiences} />
			<Route path="/portfolio">
			    <Portfolio />
			</Route>
			<Route path="/contact">
			    Contact
			</Route>
		    </Switch>
		</div>
	    </div>
        </Router>
  );
}

export default App;
